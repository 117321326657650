/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router";
import useGatsbyImageSrc from "../hooks/useGatsbyImageSrc";

function SEO({ description, lang, meta, title, image, jsonLdObject }) {
  const { pathname } = useLocation();

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  const headTitle = title
    ? `${title} | ${site.siteMetadata.title}`
    : site.siteMetadata.title;
  const metaDescription = description || site.siteMetadata.description;

  const [ogSrc] = useGatsbyImageSrc(`og/index.png`);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={headTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: `${site.siteMetadata.siteUrl}${pathname}`,
        },
        {
          property: `og:title`,
          content: title || headTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `${site.siteMetadata.siteUrl}${image || ogSrc}`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          property: `twitter:image`,
          content: `${site.siteMetadata.siteUrl}${image || ogSrc}`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        // @see https://developer.twitter.com/en/docs/twitter-for-websites/webpage-properties
        {
          name: `twitter:dnt`,
          content: `on`,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">{JSON.stringify(jsonLdObject)}</script>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `ja`,
  meta: [],
  description: ``,
  image: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  image: PropTypes.string,
};

export default SEO;
